@font-face {
  font-family: "iconfont"; /* Project id 4541016 */
  src: url('iconfont.woff2?t=1715654252704') format('woff2'),
       url('iconfont.woff?t=1715654252704') format('woff'),
       url('iconfont.ttf?t=1715654252704') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-jiarugouwuche:before {
  content: "\e81f";
}

.icon-icon:before {
  content: "\e600";
}

.icon-gouwuche:before {
  content: "\e63b";
}

.icon-icon_home:before {
  content: "\e613";
}

.icon-shangcheng11:before {
  content: "\e67b";
}

.icon-wode:before {
  content: "\e619";
}

.icon-qianbao:before {
  content: "\e6da";
}

.icon-tuandui:before {
  content: "\e62d";
}

.icon-renyuanliebiao:before {
  content: "\e7cc";
}

.icon-huodongricheng:before {
  content: "\e614";
}

.icon-shouji:before {
  content: "\e611";
}

.icon-mima:before {
  content: "\e623";
}

