html {
  height: 100%;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;


  * {
    padding: 0;
    margin: 0;
  }

  li {
    list-style: none;
  }
}

:root {
  --backGroundImage: url('../imgs/home_bg.png');
}

body {
  // background: var(--backGroundImage) !important;
}

body,
.App {
  height: 100%;
}

// 登录页的蒙层，只能在浏览器打开

.before_mask_wraper {
  height: 100%;

  .adm-mask-content {
    height: 100%;
    text-align: center;

    img {
      width: 100%;
      margin-top: 40%;
    }
  }
}

// 顶部header标题
.adm-nav-bar {
  height: 1.6rem !important;
  z-index: 99;
  position: sticky;
  top: 0;
  background-color: #fff;

  &::before {
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 110%;
    z-index: -1;
    // background: linear-gradient(180deg, #FBE6A5 0%, rgba(251, 230, 165, 0) 100%);
    background: linear-gradient(to bottom,
        rgba(166, 206, 255, 1),
        rgba(251, 230, 165, 0),
      );

  }

  .adm-nav-bar-left {
    svg {
      font-size: .4rem;
    }
  }

  .adm-nav-bar-title {

    font-weight: 400;
    font-size: 0.48rem;
    color: #323755;
    line-height: 1.07rem;
  }
}

.adm-list.adm-list-default {
  border-top: none;

  .adm-list-item {
    box-sizing: border-box;
    padding: 0;
    width: 7.87rem;
    height: 1.55rem;

    .adm-list-item-content {
      padding: 0;
      align-items: center;
      flex: 1;
    }
  }

}

.adm-tabs-content {
  padding: 0 !important;
}

.adm-list-item-content,
.adm-list-body {
  border: none !important;
  background-color: transparent !important;

  .adm-list-body-inner {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .adm-list-item.adm-form-item.adm-form-item-horizontal {
    display: flex;
    padding-left: .53rem;
    margin-bottom: .45rem;
    border-radius: .4rem;
    border: 0.03rem solid #E8E6EA;

    .adm-list-item-content-prefix {
      padding: 0;
      width: .8rem;
      height: .8rem;

      .adm-form-item-label {
        display: flex;
        align-items: center;

        i {
          font-size: .48rem;
        }
      }
    }

    .adm-list-item-content-main {
      padding: 0px;

      .adm-form-item-child-inner {
        display: flex;

        input {
          font-size: .48rem;
        }
      }
    }

    .adm-input-clear {
      padding: 0;
      padding-right: .4rem;

      svg {
        font-size: .4rem;

      }
    }

    .adm-list-item-content-extra {
      button {
        padding: 0;
        padding-right: 0.3rem;
        display: flex;
        font-size: .37rem;
      }
    }
  }
}

// 表单底部按钮
.adm-form-footer {

  button {
    border: none;
    font-size: 0.43rem;
    width: 7.87rem;
    height: 1.49rem;
    background: var(--themeColor);
    border-radius: 0.4rem;
  }
}

// 活动tab展示，票夹展示公共样式
.public_wraper {
  border-radius: .37rem;
  overflow: hidden;
  // background: linear-gradient(90deg, #FFFFFF 0%, #FFFEE1 100%);
  background: linear-gradient(90deg, rgba(251, 230, 165, 0), rgba(166, 206, 255, 1));
  box-shadow: 0rem 0.05rem 0.32rem 0rem rgba(22, 52, 80, 0.1);
  margin-bottom: .8rem;

  img {
    width: 100%;
    height: 3.89rem;
    object-fit: cover;
  }

  .footer {
    padding: .27rem .32rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ticket {
      h4 {
        font-weight: 700;
        font-size: 0.48rem;
        color: #323755;
        line-height: 0.72rem;
      }

      div {
        font-weight: 400;
        font-size: 0.37rem;
        color: #999999;
        line-height: 0.72rem;
      }
    }

    .count {
      font-weight: 400;
      font-size: 0.37rem;
      color: #323755;
      line-height: 0.72rem;
    }
  }
}

// 空态页
.adm-empty {
  padding: 100px !important;

  .adm-empty-image-container {
    svg {
      width: 3rem !important;
      height: 3rem !important;
    }
  }

  .adm-empty-description {
    font-size: .47rem !important;
  }
}

// antd-mobile的Toast组件样式
.adm-toast-main.adm-toast-main-text {
  max-width: 5rem !important;

  // .adm-auto-center-content {
  //   font-size: .45rem !important;
  // }
}

.adm-input-element {
  font-size: 0.37rem !important;
}

.adm-infinite-scroll {
  margin-top: -1rem;

  span {
    font-size: .45rem !important;
  }
}

.adm-auto-center-content {
  // padding: 20px;
  font-size: .45rem !important;
}

.adm-toast-mask .adm-toast-main {
  max-width: none !important;
}


// <Space>组件样式
.adm-space {
  flex-direction: row !important;
  align-items: baseline;
  justify-content: space-around;

  .adm-space-item {
    width: 45%;

    button {
      display: block;
      padding: .35rem 0 !important;
      font-size: .45rem !important;
      line-height: .45rem !important;
      border: 1px solid #1677ff;
    }
  }
}


// <Image>样式
.adm-image {
  overflow: hidden !important;

  .adm-image-img {
    object-position: top center;
  }
}

// 订单列表/积分列表公共样式
.that_public_list {
  padding: .35rem;
  color: #999;

  li {
    padding: .32rem;
    margin-bottom: .53rem;
    border-radius: 0.37rem;
    // background: linear-gradient(90deg, #ffffff 0%, #fffee1 100%);
    background: linear-gradient(90deg,
        rgba(251, 230, 165, 0),
        rgba(166, 206, 255, 1),
      );
    box-shadow: 0rem 0.05rem 0.32rem 0rem rgba(22, 52, 80, 0.1);


    .item_bottom,
    .header {
      display: flex;
      justify-content: space-between;
    }

    .item_bottom {
      margin-bottom: .37rem;

      .adm-image {
        border-radius: .27rem;
        // overflow: hidden;
      }
    }

    .item_top {
      display: flex;
      align-items: center;
      line-height: 0.72rem;
      font-size: .37rem;
      font-weight: 400;
      color: #999999;
      margin-bottom: .27rem;

      &>div:first-child {
        flex: 1
      }
    }

    .header {
      align-items: center;
    }

    .right_wraper {
      margin-left: .24rem;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-around;

      .header {
        div {
          font-weight: 400;
          font-size: 0.43rem;
          line-height: 0.53rem;
        }

        &>div:first-child {
          color: #323755;
        }

        &>div:last-child {
          color: rgba(0, 0, 0, 0.4);
        }
      }

      .time,
      .method {
        font-size: 0.37rem;
        color: #999999;
        line-height: 0.72rem;
      }
    }

    .price {
      margin-top: .32rem;
      font-weight: 400;
      font-size: 0.37rem;
      color: rgba(0, 0, 0, 0.4);
      line-height: 0.53rem;
      display: flex;
      justify-content: flex-end;

      span {
        font-weight: 700;
        font-size: 0.48rem;
        color: #FF4E4D;
        line-height: 0.53rem;
      }
    }
  }
}


// 底部付款，微信/支付宝展示选择样式
.that_pay_type {
  box-shadow: 0rem 0.05rem 0.32rem 0rem rgba(22, 52, 80, 0.1);
  border-radius: 0.37rem;
  padding: .56rem .45rem;
  margin-bottom: .8rem;
  // background: linear-gradient(90deg, #FFFFFF 0%, #FFFEE1 100%);
  background: linear-gradient(90deg,
      rgba(251, 230, 165, 0),
      rgba(166, 206, 255, 1),
    );

  li {
    display: flex;
    justify-content: space-between;

    &:first-child {
      margin-bottom: .48rem;
    }

    div {
      font-weight: 700;
      font-size: 0.43rem;
      color: #323755;
      line-height: 0.53rem;
    }
  }

  .weixin,
  .alipay {
    display: flex;
    align-items: center;

    &::before {
      content: "";
      display: block;
      margin-right: .21rem;
      background-size: 100% 100%;
      height: .64rem;
      width: .64rem;
      background-image: url('../imgs/weixin.png');
    }
  }

  .alipay {
    &::before {
      background-image: url('../imgs/alipay.png');
    }
  }
}

// 勾选样式
.common_rem {
  &::before {
    width: .7rem;
    height: .7rem;
    content: "";
    display: block;
    background: url("../imgs/uncheck.png") no-repeat center center;
    background-size: 100% auto;
  }

  &.isChecked {
    &::before {
      width: .7rem;
      height: .7rem;
      content: "";
      display: block;
      background: url("../imgs/checked.png") no-repeat center center;
      background-size: 100% auto;
    }
  }
}

// 运营/用户的排队列表公共样式
.that_queue_list {
  padding: .35rem;

  li {
    display: flex;
    flex-direction: row;
    padding: .43rem;
    font-size: 0.37rem;
    border-radius: 0.37rem;
    // background: linear-gradient(90deg, #FFFFFF 0%, #FFFEE1 100%);
    background: linear-gradient(90deg,
        rgba(251, 230, 165, 0),
        rgba(166, 206, 255, 1),

      );
    box-shadow: 0rem 0.05rem 0.32rem 0rem rgba(22, 52, 80, 0.1);
    margin-bottom: .53rem;

    .img_wraper {
      // width: 45% !important;
      flex-shrink: 0;

      .adm-image {
        border-radius: .27rem;

        img {
          border-radius: .27rem;
        }
      }
    }

    .item_content_wraper {
      margin-left: .32rem;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;

      .name {
        font-weight: 400;
        font-size: 0.6rem;
        color: #323755;
        line-height: 0.53rem;
        margin-bottom: 0.48rem;
      }

      .center {

        .queue,
        .wait,
        .time {
          display: flex;
          align-items: center;
          margin-bottom: .27rem;
          font-weight: 400;
          font-size: 0.37rem;
          color: #999999;
          line-height: 0.72rem;

          svg {
            margin-right: .27rem;
            height: .43rem;
            width: .43rem;
          }

          // &::before {
          //   flex-shrink: 0;
          //   content: "";
          //   display: block;
          //   margin-right: .27rem;
          //   background-size: 100% 100%;
          //   height: .43rem;
          //   width: .43rem;
          //   background-image: url('../../asset/imgs/wait.png');
          // }

          div {
            flex: 1;
            white-space: nowrap;
            display: flex;
            align-items: center;
            // flex-shrink: 0;
          }

          span {
            color: #999;
            font-weight: 400;
            // text-align: right;
          }

          &>span:last-child {
            color: var(--themeColor);
            flex: flex;
            flex-direction: row-reverse;
            white-space: wrap;
            word-break: break-all;
          }
        }

        .queue {
          &::before {
            // background-image: url('../../asset/imgs/queue.png');
          }
        }

        .time {
          &::before {
            // background-image: url('../../asset/imgs/time.png');
          }
        }
      }
    }
  }
}

// 运营端顶部名牌（团成员展示）公共样式
.activity_public {
  position: relative;
  padding: .35rem;

  .that_item {
    display: flex;
    flex-direction: row;
    padding: .43rem;
    font-size: 0.37rem;
    border-radius: 0.37rem;
    // background: linear-gradient(90deg, #FFFFFF 0%, #FFFEE1 100%);
    background: linear-gradient(90deg,
        rgba(251, 230, 165, 0),
        rgba(166, 206, 255, 1),
      );
    box-shadow: 0rem 0.05rem 0.32rem 0rem rgba(22, 52, 80, 0.1);
    margin-bottom: .53rem;

    .adm-image {
      border-radius: .27rem;

      img {
        border-radius: .27rem;
      }

    }

    .item_content_wraper {
      margin-left: .32rem;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;

    }

    .name {
      text-align: center;
      font-weight: 400;
      font-size: 0.8rem;
      color: #323755;
      margin-bottom: 0.48rem;
    }
  }
}