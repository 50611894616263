.__fusion_manage_loading {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99999999;
    display: flex;
    align-items: center;
    justify-content: center;
    

    &>div {
        width: 4rem;
        padding: 0.32rem;
        // height: 1.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: 0.21333rem;
        word-break: break-all;
        background-color: rgba(0, 0, 0, 0.7);
        font-size: .45rem !important;
        
        span {
            color: white;
        }

        .ant-spin-text {
            color: #fff;
        }

        .ant-spin-dot-item {
            background: #fff !important;
        }
    }
}